<template>
	<div class="loginContent">
		<img class="logo" src="../../res/img/logo.png" @click="Jump">

		<!-- 登录表单 -->
		<el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item prop="tel">
				<el-input v-model="tel" placeholder="请输入手机号" clearable>
					<el-button slot="prepend">手机号</el-button>
				</el-input>
			</el-form-item>
			<el-form-item prop="check_code">
				<el-input v-model="check_code" placeholder="请输入验证码" clearable ref="input">
					<el-button slot="prepend">验证码</el-button>
					<el-button @click="get_verify" type="success" slot="append" style="width:112px">{{verifyTips}}</el-button>
				</el-input>
			</el-form-item>
		</el-form>
		<div class="sub_btn">
			<el-button @click="login" style="width: 100%;" type="primary">立即登录</el-button>
		</div>
	</div>
</template>

<script>
	import bg from '@/my/bg.js'
	export default {
		data() {
			return {
				tel: '',
				check_code: '',
				sec:60,
				verifyTips: '获取验证码',

				login_lock:false,
				check_code_lock:false,
			}
		},
		created() {

			bg.init()
		},
		mounted(){
			this.enter()
		},
		methods: {
			Jump(){
				// 跳转新页面
				var test = window.location.host
				window.open("http://"+test);
			},
			//回车登陆
			enter(){
				document.onkeydown =(e)=>{
					if(e.keyCode == '13'&&e.target.baseURI.match(/login/)){
					    this.login()
					}
				}
			},
			//登录
			login(){

				//是否锁定
				if(this.login_lock){
					this.$my.other.msg({
						type:'warning',
						str:'登陆中,不要重复点击',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}

				//验证码校验
				if(!(this.$my.check.is_num(this.check_code)&&this.check_code.length==4)){
					this.$my.other.msg({
						type:'warning',
						str:'验证码只能是4位数字',
					})
					return;
				}

				//登录
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'login',
						tel:this.tel,
						ide:this.check_code,
					},
					callback:(data)=>{

						//缓存数据
						this.$my.cache.set({
							is_login:1,
							user_info:{
								...data.user_info
							},
						});

						this.$router.push('/pages');

						//尝试取出期望抵达页面
						//let old_path=this.$my.cache.get('_before_login_to_page')

						//去哪?
						// if(old_path){
						//	 this.$router.push(old_path.fullPath);
						// }else{
						//	 this.$router.push('/pages/cargo/cargo_creat');
						// }
					}
				});
			},

			//获取验证码
			get_verify(){
				//是否锁定
				if(this.check_code_lock){
					this.$my.other.msg({
						type:'warning',
						str:'60秒内不能重复获取验证码',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}

				//发送中...
				this.verifyTips="获取验证码";
				//发送请求
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'send_ide_for_admin_login',
						tel:this.tel
					},
					callback:(data)=>{
						//锁定发送按钮
						this.check_code_lock=true;

						//开始倒计时
						this.interval_id=setInterval(()=>{
							this.verifyTips=this.sec+"S";
							this.sec--;
							if(this.sec==0){
								this.check_code_lock=false;
								this.sec=60;
								this.verifyTips="重新发送";
								clearInterval(this.interval_id)
							}
						},1000);
					}
				});
				this.$nextTick(() => {
					this.$refs.input.focus()
				})
			},
		}
	}
</script>
<style lang="scss" scoped>
	.bg-paper{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: #666;
		//background: url(../../res/img/bg-paper.jpg) no-repeat 50%;
		background-size: cover;
	}

	.loginContent {
		width: 340px;
		position: absolute;
		top: 100px;
		left: calc(50% - 170px);

		.logo{
			width: 100px;
			display: block;
			margin: auto;
		}
	}

	.bottom_links{

		padding: 0 10px;

		.text{

		}
		.links{
			margin-top: 10px;
			display: flex;
			flex-wrap: wrap;
			.item{
				font-size: 12px;
			}
		}
	}

	.sub_btn{
		margin-top: 15px;
	}
</style>